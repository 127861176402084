<template>
  <div class="page" id="print">
    <el-row class="outer-row" >
      <el-col :span="24">
        <div class="pdl20div">
          <el-descriptions title="用户信息" :column="1">
            <el-descriptions-item label="用户名称"
              >{{form.user.userName}}</el-descriptions-item
            >
            <el-descriptions-item label="联系人"
              >{{form.user.contact}}</el-descriptions-item
            >
            <el-descriptions-item label="电话">{{form.user.userPhone}}</el-descriptions-item>
            <el-descriptions-item label="身份证号">{{form.user.userIdnumber}}</el-descriptions-item>
            <el-descriptions-item label="营业执照号">{{form.user.businessLicenseNumber}}</el-descriptions-item>
            <el-descriptions-item label="用户分类">{{form.user.userTypeName}}</el-descriptions-item>
            <el-descriptions-item label="片区">{{form.user.addrName}}</el-descriptions-item>
            <el-descriptions-item label="地址">{{form.user.address}}</el-descriptions-item>
            <el-descriptions-item label="负责部门">{{form.user.deptName}}</el-descriptions-item>
            <el-descriptions-item label="表具">{{form.user.tableCount}}</el-descriptions-item>
          </el-descriptions>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="right-part">
          <div class="title">安检情况</div>
          <div class="right-content">
            <div class="item" v-for="(item,index) in form.datas" :key="index" 
            :style="{borderBottom:index+1!=form.datas.length ? '1px solid #DCDFE6' : (item.safetyResults == 1 ? '0px' : '1px solid #DCDFE6'),
                  marginBottom:index+1!=form.datas.length ? '10px' : '0px'}">
              <div class="first-line">
                <div>
                  <span class="left">检查时间：</span>
                  {{item.checkTime}}
                </div>
                <div>
                  <span class="left">安检员：</span>
                  {{item.inspectorName}}
                </div>
                <div>
                  <span class="left">检查时长：</span>
                  {{item.inspectionTime}}
                </div>
                <div>
                  <span class="left">安检结果：</span>
                  {{item.safetyResultsName}}
                  <el-popover width="300" @show="detailAddress(item.location,132)">
                    <div :id="'container'" style="height:300px;width:300px"></div>
                    <el-button type="text" slot="reference">打卡位置</el-button>
                  </el-popover>
                </div>
              </div>
              <div class="second-line"  v-if="item.url">
                <el-image
                  v-if="item.url"
                  style="width: 100px; height: 100px"
                  :src="item.url.split(',')[0]"
                  :preview-src-list="item.url.split(',')"
                >
                </el-image>
                <!-- <img style="width: 100px; height: 100px;border:1px solid #dddddd;margin:5px;" :src="require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)" v-for="(fifth,int5) in [require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)]" :key="int5" alt=""/> -->
              </div>
              <!-- <el-divider></el-divider> -->
            </div>
            <!-- 信息采集 -->
            <div class="infoCollect" 
                style="border-top:0px"
                   v-if="
									form.datas && form.datas[form.datas.length - 1]
										.safetyResults == 1
								">
              <div class="title">信息采集 </div>
              <!-- v-model="activeNames" -->
              <el-collapse v-model="activeNames">
                <el-collapse-item :title="el.sheetSteel" name="1" v-for="(el,first) in form.table" :key="first">
                  <div>
                    <div class="item-title">表具信息</div>
                    <el-descriptions>
                      <el-descriptions-item label="燃气表类型">{{el.tableTypeName}}</el-descriptions-item>
                      <el-descriptions-item label="表向">{{el.tableDirectionName}}</el-descriptions-item>
                      <el-descriptions-item label="燃气表厂家">{{el.tableVenderName}}</el-descriptions-item>
                      <el-descriptions-item label="表封号">{{el.tableTitles}}</el-descriptions-item>
                      <el-descriptions-item label="开户日期">{{el.openDate}}</el-descriptions-item>
                      <el-descriptions-item label="所接炉具">{{el.receiveStove}}</el-descriptions-item>
                      <el-descriptions-item label="所购气量">{{el.purchasedVolume}}</el-descriptions-item>
                      <el-descriptions-item label="最近购气日期">{{el.recentlyDate}}</el-descriptions-item>
                      <el-descriptions-item label="启用状态">{{el.tableTypeName}}</el-descriptions-item>
                    </el-descriptions>
                  </div>
                  <div>
                    <div class="item-title">采集信息</div>
                    <el-descriptions :column="1" v-for="(item1, second) in el.item" :key="second" direction="vertical">
                        <el-descriptions-item :label="'● '+item1.itemName" :label-style="{width:'120px',color:'#444444',fontWeight: 'bold',borderBottom:'8px'}">
                          <template v-if="item1.inputType == 1 || item1.inputType == 5" >
                            {{item1.note}}
                          </template>
                          <div v-if=" item1.inputType == 2 || item1.inputType == 3 " >
                            <div v-for="item2 in item1.items" :key=" el.tableId + 'b' + item2.items " style="position: relative">
                              <template v-if=" item2.isPitchUp " >
                                <img class=" icon-img " :src=" item2.type == 1 ? require('../../../assets/normal1.png') : require('../../../assets/warning1.png') " />
                                {{item2.content}}
                                <div class=" describ " v-if=" item2.note "  style=" min-width: 180px; " >
                                  {{ item2.note }}
                                </div>
                                <div class=" imgContain " v-if=" item2.url "  >
                                  
                                   <img style="width: 100px; height: 100px;border:1px solid #dddddd;margin:5px;" :src="fourths" v-for="(fourths,int4) in item2.url.split(',')" :key="int4" alt=""/>
                                   <!-- <img style="width: 100px; height: 100px;border:1px solid #dddddd;margin:5px;" :src="require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)" v-for="(fifth,int5) in [require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)]" :key="int5" alt=""/> -->
                                </div>

                              </template>
                              <!-- 关联项 -->
                              <!-- <div style="color:#909399">采集关联项：</div> -->
                              <template v-for="item3 in item2.junior">
                                  <div
                                    v-if="item2.isPitchUp && item3.isPitchUp"
                                      style="margin-left:-6px; "
                                      :key="
                                        el.tableId +
                                        item3.juniorId
                                      "
                                  >
                                  <!-- :column="
                                          1
                                        "
                                        v-for="item3 in item2.junior"
                                        :key="
                                          el.tableId +
                                          item3.juniorId
                                        " -->
                                    <el-descriptions direction="vertical">
                                      <el-descriptions-item
                                          :label-style="{color:'#444444',fontWeight: 'bold'}"
                                          :label="'● '+item3.juniorName"
                                      >
                                        <template  v-if="item3.juniorInputType == 1 || item3.juniorInputType == 5">
                                          {{item3.note }}
                                        </template>
                                        <div  v-if="item3.juniorInputType ==2 ||item3.juniorInputType ==3">
                                          <div
                                              v-for="item4 in item3.juniors"
                                              :key="
                                                el.tableId +
                                                'b' +
                                                item4.juniorsId
                                              "
                                          >
                                            <template v-if="item4.isPitchUp">
                                              <img
                                                  class="icon-img"
                                                  :src="item4.juniorsType ==1
                                                      ? require('../../../assets/normal1.png')
                                                      : require('../../../assets/warning1.png')"
                                              />
                                              {{item4.juniorsContent}}
                                              <div
                                                  class="describ"
                                                  v-if="item4.note"
                                                  style="min-width: 180px;"
                                              >
                                                {{
                                                  item4.note
                                                }}
                                              </div>
                                              <div class="imgContain" v-if="item4.url" >
                                                <img style="width: 100px; height: 100px;border:1px solid #dddddd;margin:5px;" :src="firsts" v-for="(firsts,int1) in item4.url.split(',')" :key="int1" alt=""/>
                                                <!-- <img style="width: 100px; height: 100px;border:1px solid #dddddd;margin:5px;" :src="require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)" v-for="(fifth,int5) in [require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)]" :key="int5" alt=""/> -->
                                              </div>
                                            </template>
                                          </div>
                                        </div>
                                        <div  v-if="item3.juniorInputType ==4">
                                          <div class="imgContain" v-if="item3.url">
                                            <!-- <el-image
                                            v-for="(url,index4) in item3.url.split(',')" :key="index4"
                                            style="width: 100px; height: 100px;border:1px solid #dddddd;margin:5px;"
                                            :src="url"
                                            :preview-src-list="item3.url.split(',')">
                                          </el-image> -->
                                          <img style="width: 100px; height: 100px;border:1px solid #dddddd;margin:5px;" :src="sectids" v-for="(sectids,int2) in item3.url.split(',')" :key="int2" alt=""/>
                                          <!-- <img style="width: 100px; height: 100px;border:1px solid #dddddd;margin:5px;" :src="require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)" v-for="(fifth,int5) in [require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)]" :key="int5" alt=""/> -->
                                          </div>
                                        </div>
                                      </el-descriptions-item>
                                    </el-descriptions>
                                  <!-- </div>
                                  
                                  </template>
                            </div>
                          </div>
                          <div class="right" v-if="item1.inputType == 4">
                            <div class="imgContain" v-if="item1.url" >
                              <el-image
                                v-for="(url,index3) in item1.url.split(',')" :key="index3"
                                style="width: 100px; height: 100px;border:1px solid #dddddd;margin:5px;"
                                :src="url"
                                :preview-src-list="item1.url.split(',')">
                              </el-image>
                            </div>
                          </div>
                        </el-descriptions-item>
                      </el-descriptions> -->
                              </div>
                            </template>
                          </div>
                        </div>
                        <div class="right" v-if="item1.inputType==4">
                          <div class="imgContain" v-if="item1.url">
                            <!-- <el-image
                              v-for="(url,index) in item1.url.split(',')" :key="index"
                              style="width: 100px; height: 100px"
                              :src="url"
                              :preview-src-list="item1.url.split(',')">
                            </el-image> -->
                            <img style="width: 100px; height: 100px;border:1px solid #dddddd;margin:5px;" :src="thirdlys" v-for="(thirdlys,int2) in item1.url.split(',')" :key="int2" alt=""/>
                            <!-- <img style="width: 100px; height: 100px;border:1px solid #dddddd;margin:5px;" :src="require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)" v-for="(fifth,int5) in [require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)]" :key="int5" alt=""/> -->
                          </div>
                        </div>
                      </el-descriptions-item>
                    </el-descriptions>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <!-- 用户确认 -->
            <div class="userConfirm" v-if="
									form.datas && form.datas[form.datas.length - 1]
										.safetyResults == 1
								">
              <div class="left">用户确认：</div>
              <div v-if="form.userSignature">
                <img style="width: 100px; height: 100px;border:1px solid #dddddd;margin:5px;" :src="items" v-for="(items,int1) in form.userSignature.split(',')" :key="int1" alt=""/>
                <!-- <img style="width: 100px; height: 100px;border:1px solid #dddddd;margin:5px;" :src="require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)" v-for="(fifth,int5) in [require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)]" :key="int5" alt=""/> -->
              </div>
              <div class="imgContain" v-if="form.userUrl" style="margin:0 5px">
                <!-- <el-image
                 v-if="form.userUrl"
                  style="width: 100px; height: 100px"
                  :src="form.userUrl.split(',')[0]" 
                  :preview-src-list="form.userUrl.split(',')">
                </el-image> -->
                <img style="width: 100px; height: 100px;border:1px solid #dddddd;margin:5px 0;" :src="items" v-for="(items,int) in form.userUrl.split(',')" :key="int" alt=""/>
                <!-- <img style="width: 100px; height: 100px;border:1px solid #dddddd;margin:5px 0;" :src="require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)" v-for="(fifth,int5) in [require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`)]" :key="int5" alt=""/> -->
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
 
<script>
import {dataParticulars} from "@/RequestPort/workorder/maintenRecord";
export default {
  name: "unresidentDetail",
  data() {
    return {
      activeNames: ['1'],
      srcList:[],
      // 表单
      form:{
        user:{}
      },
      garyimg: require(`@/assets/biIndex/tonghua${Math.floor(Math.random()*5)}.png`),
    };
  },
  mounted(){
    if(this.$route.query.id){
       this.particulars(this.$route.query.id)
     }
        // window.onafterprint = (e) => {
        //    this.$router.go(-1)
        // };
  },
  methods: {
    particulars(val){
      dataParticulars({dataId:val}).then(res => {
        this.form = {...this.form,...res.data}
        this.$nextTick((e)=>{
              this.$print('#print', {
                              afterprint: () => {
                                // this.$router.go(-1)
                              },
                              cancel: () => {
                                this.$router.go(-1)
                              }
                     },63)
          })
      })
    },
    detailAddress(e,index){
      // this.$refs.mapposition.setMapCenter({
      //   checkinLocation: e
      // })
      if (e) {
        this.$nextTick(() => {
          console.log([Number(e.split(' ')[0]),Number(e.split(' ')[1])],index)
          var map = new AMap.Map('container', {
            zoom: 15,//级别
            center: [Number(e.split(' ')[0]),Number(e.split(' ')[1])]
          });
          var marker = new AMap.Marker({
              position:[Number(e.split(' ')[0]),Number(e.split(' ')[1])]//位置
          })
          map.add(marker);//添加到地图
        })
      } else {
        this.$message.error('无打卡位置信息')
      }
    },
    close() {
      this.$router.back(-1)
    },
  },
};
</script>
 
<style scoped lang="less">
.pageDetails {
  padding: 10px;
  width: 100%;
  background: #f3f4f7;
}

.page {
  // height: 100%;
  padding: 10px;
  height: calc(100vh - 91px);
  overflow: auto;
  background: #fff;

  box-sizing: border-box;

  .pdl20div {
    ::v-deep.el-descriptions {
      .el-descriptions__body {
        padding-left: 20px;
      }
    }
  }

  ::v-deep {
    .el-form-item {
      margin-bottom: 0;
    }

    .colsite {
      margin-bottom: 20px;
    }
  }
// 采集信息
.info{
  /deep/.el-descriptions-item__content{
    padding:0 0 19px 10px;
  }
  /deep/.el-collapse-item__content{
    padding-bottom: 0px;
  }
}
  .create {
    width: 100%;
    height: 50px;
    // background-color: burlywood;
    display: flex;
    flex-direction: row;
    align-items: center;

    .create_size {
      font-size: 20px;
      font-weight: 500;
      color: #252700;
      margin-left: 20px;
    }
  }

  // .box {
  //   width: 96%;
  //   margin: 0 auto;
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: space-between;


  //   .tableRight {
  //     width: 100%;
  //     margin-top: 5px;
  //     margin-right: 25px;

  //     .btn {
  //       width: 210px;
  //       background: #0f74d5;
  //       border-radius: 4px;
  //       float: right;
  //       margin-right: 28%;
  //     }

  //     .title {
  //       padding: 10px;
  //       margin-bottom: 20px;
  //     }

  //     .row {
  //       padding: 12px 30px 0 30px;
  //     }
  //   }
  // }
}

.right-part {
  padding-left: 30px;
  height: calc(100% - 50px);
  overflow: auto;

  .title {
    font-size: 16px;
    font-weight: 700;
  }
  .titleTwo{
    font-weight: 400;
    color: #303133;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .right-content {
    padding: 30px;

    .first-line {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      // border: 1px solid #DCDFE6;
      padding: 17px 17px 10px;
      div {
        width: 50%;
        margin-bottom: 19px;

        > span {
          color: #545454;
        }

        button {
          padding: 0;
          margin-left: 40px;
        }
      }
      .left{
        color: #909399;
      }
    }
    .item{
      border: 1px solid #DCDFE6;
    }
  }
/deep/.el-collapse-item__header{
  padding: 0 21px;
}
/deep/.el-collapse-item__wrap{
  padding: 0 21px;
}
/deep/.el-divider{
  margin: 0 0 19px 0;
  width: 100%;
}
  .infoCollect {
    border: 1px solid #ececec;
    padding:0 17px 33px 17px;

    // .item-title::before {
    //   content: "| ";
    //   color: #7cbaff;
    // }
    .item-title{
      background: linear-gradient(90deg, #D7E7FF 0%, #D7E7FF 100%);
      font-weight: 400;
      color: #303133;
      margin: 19px 0;
      width: 56px;
      font-size: 14px;
    }
    /deep/ .el-descriptions {
      // padding: 0px 63px;
      
      .el-descriptions-item__label:not(.is-bordered-label) {
        color: #909399;
        // width: 120px;
      }

      .el-descriptions-item__content {
        // display: block;
        color: #303133;
        font-size: 14px;
        .ques1,
        .ques2 {
          line-height: 30px;
          img {
            width: 16px;
            height: 16px;
            vertical-align: text-top;
          }
        }
        .ques2 {
          padding-left: 20px;
        }
      }
    }
  }
  .describ{
    color: #303133;
        font-size: 14px;
            margin-left: 17px;
  }
// /deep/.el-descriptions__body{
//         background-color: transparent;
//       }
  .imgContain {
    margin: 0 14px;
    .el-image {
      margin: 0 10px 10px 0;
    }
  }

  .userConfirm {
    // margin-top: 24px;
    // display: flex;
    margin:24px 0px 0;
    .left {
      min-width: 66px;
      margin-right: 32px;
    }
  }
}

.outer-row {
  height: calc(100% - 50px);

  .el-col {
    height: 100%;
  }

  .el-col:first-child {
    border-right: 1px solid #f1f1f1;
  }
}

// /deep/ .el-descriptions-item__label {
//   width: 70px;
// }

/deep/ .el-descriptions__table {
  tbody {
    .el-descriptions-item {
      padding-bottom: 20px;
    }
  }
}
// 折页样式
/deep/.el-collapse-item__header{
  background-color: #F6F6F6;
}
::v-deep.el-descriptions__title::before,
.title::before {
  content: "| ";
  font-weight: bold;
  color: #1082ff;
}
.icon-img {
  width: 16px;
  height: 16px;
}
</style>