import { render, staticRenderFns } from "./detailsPrint.vue?vue&type=template&id=2d780aa8&scoped=true"
import script from "./detailsPrint.vue?vue&type=script&lang=js"
export * from "./detailsPrint.vue?vue&type=script&lang=js"
import style0 from "./detailsPrint.vue?vue&type=style&index=0&id=2d780aa8&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d780aa8",
  null
  
)

export default component.exports